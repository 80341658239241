import React, { Component } from 'react';
import { Form, Row, Col } from 'reactstrap';
import { request, request_file } from './request.js';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/dist/jquery.min.js';
import './App.css';
import './fonts/css/open-iconic.css';
import 'animate.css';
import imagen from './imagenes/hombre.png';

class App extends Component 
{

	constructor(props)
	{
		super(props)
		this.state = {
			registro :
			{
				curp : '',
				nombre : '',
				apellido1 : '',
				grupo : ''
			},
			movimientos : [],
			foto : ''
		}
		this.buscarEntradas = this.buscarEntradas.bind(this);
	}

	submit=(evt)=>{
		evt.preventDefault();
	}

	handleInputChange=(event)=>
	{
		const target = event.target;
		const value = target.value;

		var { registro } = this.state;

		registro['curp'] = value;

		this.setState({ registro : registro });
	}

	buscarEntradas()
	{
		var { registro, movimientos, foto } = this.state;
		//var arreglo = movimientos;
		let temp = this;

		request.get(`/ConsultarEntradas/${registro['curp']}`)
		.then(function(response)
		{
			if(response.status === 200)
			{
				if(response.data)
				{
		 			registro = response.data.registro;
		 			movimientos = response.data.movimientos;

		 			
		 			temp.setState({ registro : registro, movimientos : movimientos });
		 		}
		 		else
		 		{
		 			registro.curp="";
		 			registro.nombre="";
		 			registro.apellido1="";
		 			registro.grupo="";
	 				Swal.fire({
						position: 'top-end',
						icon: 'error',
						title: 'No Registrado',
						toast: true,
						showConfirmButton: false,
						timer: 1500
					});
		 			temp.setState({ registro : registro });
		 		}
		 	}
		})
		.catch(error => {} );
		console.log(registro['curp']);

		request_file().post(`/MostrarImagen2?curp=${registro['curp']}`)
		.then(response =>
		{
			const file = new Blob([response.data], {type: 'image/jpeg' });
			const fileURL = URL.createObjectURL(file);
			foto=fileURL;
			temp.setState({ foto : foto });
		})
		.catch(error => { });

		this.setState({ registro : registro, movimientos : movimientos });
	}

	render()
	{
		let { registro, conectado, movimientos, foto } = this.state;

		return (
			<div className="body">
				<div className="main">
					<Row className="pl-1 pr-1">
						<Col className="col-12">
							<Form onSubmit={this.submit}>
								<Row>
									<Col>
										<div className="pl-1 pr-1 input-group mb-1 loginForm">
											<span className="input-group-text" id="basic-addon1"> Consulta de Registros de Entrada y Salida </span>
											<input type="text" disabled className="form-control" />
											<span className="input-group-text">Esc. Sec. #5 Moises Saenz Garza</span>
										</div>
									</Col>
								</Row>
							</Form>
		{/* CUADRO DE FOTO */}					
							<Row>
								<Col>
									<div className="pl-2 pr-2 input-group mb-1 loginForm">
										<table>
											<tr className="f ml-2">
												<td className="f">
													<img className="cuadroFoto" align="middle" src={foto} class="img-thumbnail" alt="..."/>
												</td>
												<td className="datos">
													<div className="pr-1 input-group mb-1">
														<span className="input-group-text" id="basic-addon1"> CURP: </span>
														<input type="text" autoFocus autoComplete="off" className="form-control" placeholder="Escribe el CURP a consultar" aria-label="Credencial" aria-describedby="basic-addon1" onChange={this.handleInputChange}/>
														<button className="btn btn-primary" type="button" id="button-addon2" onClick={this.buscarEntradas} >BUSCAR</button>
													</div>
													<div className="pr-1 input-group mb-1">
														<span className="input-group-text" id="basic-addon1"> NOMBRE(S): </span>
														<input type="text" disabled className="form-control" aria-label="nombre" name="nombre" id="nombre" value={registro.nombre}/>
														
													</div>
													<div className="pr-1 input-group mb-1">
														<span className="input-group-text" id="basic-addon1"> APELLIDOS: </span>
														<input type="text" disabled className="form-control" aria-label="apellido1" name="apellido1" id="apellido1" value={registro.apellido1}/>
														
													</div>
													<div className="pr-1 input-group mb-1">
														<span className="input-group-text" id="basic-addon1"> GRUPO: </span>
														<input type="text" disabled className="form-control" aria-label="grupo" name="grupo" id="grupo" value={registro.grupo}/>
													</div>
												</td>
											</tr>
										</table>
									</div>
								</Col>
							</Row>
								
		{/* LISTA DE REPORTE */}
							<Form>
								<Row>
									<Col>
										<div className="pl-2 pr-2 loginForm maxh lista">
											<table className="table table-responsive-sm table-striped table-bordered table-sm">
												<tbody>
												{
													movimientos.map((item, i) => 
														<tr>
															<td className="cm cen ne"> { movimientos.length-i } </td>
															{
																parseInt(item.tipo)===1 ?
																	<td className="cc cen"> <span className="oi ve" data-glyph="arrow-thick-top" aria-hidden="true"></span> </td>
																:
																	<td className="cc cen"> <span className="oi ro" data-glyph="arrow-thick-bottom" aria-hidden="true"></span> </td>
															}
															{
																//parseInt(item.mensaje)===0 ?
																	<td className="cc cen"> <span className="oi ro" data-glyph="envelope-open" aria-hidden="true"></span> </td>
																//:
																//	<td className="cc cen"> <span className="oi ve" data-glyph="envelope-open" aria-hidden="true"></span> </td>
															}
															<td className="cx cen ne"> {item.fecha} </td>
															<td className="cg cen ne"> {item.hora} </td>
														</tr>
													)
												}
												</tbody>
											</table>
										</div>
									</Col>
								</Row>
							</Form>
							
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

export default App;